import { AllotmentHandle } from 'allotment';
import { useNavigate, useOutlet, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useApplication } from '../../wrappers/application-context/application-context';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useAuth } from '../../auth';
import { getFlags, isAutoUser, LAST_SELECTED_WORKSPACE_BY_ORG } from '../../lib/utils';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { IdeLayout } from '@xspecs/design-system/src/components/ide-layout/ide-layout';
import { FileTreeItem } from '@xspecs/single-source-model/src/read-models/file-tree/FileTree.types';
import { JsonEditor } from '../../components/single-source-model/entity-details/json-editor';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import {
  AppTypeEvent,
  DisconnectFileCommand,
  FileType,
  LoadFileCommand,
  Status as StatusEnum,
} from '@xspecs/single-source-model';
import { Workspace } from '../../state/types';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { showWorkspaceSettingsVar } from '../../state/state';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  RENAME_WORKSPACE_MUTATION,
  TRIGGER_BUILD_CLEAN_MUTATION,
  TRIGGER_CODE_GENERATION_MUTATION,
  TRIGGER_DEPLOYMENT_MUTATION,
  TRIGGER_IMPLEMENTATION_MUTATION,
} from '../../graphql/mutations';
import { useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { AutoLogoSkeleton } from '@xspecs/design-system/src/ui/icons/components/auto-logo-skeleton';
import { useTheme } from '@xspecs/design-system';
import { useFileStatus } from '../../hooks/use-file-status';
import { useMenuItemSettings } from '../../hooks/use-menu-item-settings';
import { useFileIsLoaded } from '../../hooks/use-file-is-loaded';

export const SpaceRoute = () => {
  const { fileId } = useParams();

  const { application } = useApplication();
  // const entityBaseId = fileId ? fileId.split('-')[0] : ''; TODO: Fix this @osama
  const outlet = useOutlet({ fileId: fileId });
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef<AllotmentHandle>(null);
  const { formatMessage: f } = useIntl();
  const auth = useAuth();
  const logout = auth?.logout;

  const menuItemSettings = useMenuItemSettings();

  const buildLog = useSingleSourceStore.use.buildLog();

  const { organization } = useActiveOrganization();
  const { workspace, setWorkspace } = useActiveWorkspace();
  const spaceFileId = organization && workspace ? `${organization.id}/${workspace.id}` : undefined;

  const { fileStatus } = useFileStatus(spaceFileId);
  const { synced } = useFileIsLoaded(spaceFileId);

  const collapseSidebar = useSingleSourceStore.use.collapseSidebar();
  const setCollapseSidebar = useSingleSourceStore.use.setCollapseSidebar();
  const setRecentSpacesByOrg = useSingleSourceStore.use.setRecentSpacesByOrg();
  const { user } = useAuth();
  const isFirstRender = useRef(true);

  const { theme } = useTheme();

  const fileTree = useSingleSourceStore.use.fileTree();
  const file = fileTree.items?.find((item) => item.id === fileId);
  const isModalFile = ['em', 'ndd', 'ndem'].includes(file?.data.fileType || '');
  const navigate = useNavigate();

  const apolloClient = useApolloClient();

  const [renameWorkspace] = useMutation(RENAME_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();

  const { addToast } = useSnackStack();

  const [triggerCodeGeneration, { data: codeGenData, error: codeGenError }] = useMutation(
    TRIGGER_CODE_GENERATION_MUTATION,
  );
  const [triggerImplementation, { data: implementData, error: implementError }] = useMutation(
    TRIGGER_IMPLEMENTATION_MUTATION,
  );
  const [triggerDeployment, { data: deployData, error: deployError }] = useMutation(TRIGGER_DEPLOYMENT_MUTATION);
  const [triggerBuildClean, { data: cleanBuildData, error: cleanBuildError }] =
    useMutation(TRIGGER_BUILD_CLEAN_MUTATION);

  const onDragEnd = useCallback((sizes: number[]) => {
    localStorage.setItem('allotments', JSON.stringify(sizes));
  }, []);

  const defaultSizes = useMemo(() => {
    const DEFAULT = [300, 2000];
    // const x = JSON.parse(localStorage.getItem('allotments') ?? 'null');
    // if (!x || x.length !== DEFAULT.length) localStorage.setItem('allotments', JSON.stringify(DEFAULT));
    // return x ?? DEFAULT;
    return DEFAULT;
  }, []);

  const onCollapseSidebar = useCallback(() => {
    setCollapseSidebar(!collapseSidebar);
  }, [collapseSidebar, setCollapseSidebar]);

  useEffect(() => {
    if (!workspace || !organization) return;
    const currentLastSelectedWorkspaceByOrg = JSON.parse(
      // localStorage.getItem(LAST_SELECTED_WORKSPACE_BY_ORG) ||
      '{}',
    );
    localStorage.setItem(
      LAST_SELECTED_WORKSPACE_BY_ORG,
      JSON.stringify({
        ...currentLastSelectedWorkspaceByOrg,
        [organization.id]: workspace.id,
      }),
    );
  }, [organization, workspace]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const [sidebar = 300, canvas = 700, detailsPanel = 200] = JSON.parse(localStorage.getItem('allotments') ?? '[]');
    if (collapseSidebar) {
      ref.current?.resize([64, canvas + sidebar - 64, detailsPanel]);
    } else {
      ref.current?.resize([sidebar, canvas, detailsPanel]);
    }
  }, [collapseSidebar]);

  const onSelectFile = (file: FileTreeItem) => {
    // TODO: FIX THIS - Rinor hacking
    const id = `${file.id}-json`;
    application?.context.ideInteractor.addPanel({
      ...file,
      data: {
        ...file.data,
        // @ts-ignore
        panel:
          file.text === 'Event Model'
            ? {
                props: { nodes: [{ id: 'test', data: { label: 'test' }, position: { x: 100, y: 200 } }] },
              }
            : {
                component: JsonEditor,
                props: { id: id },
              },
      },
    });
  };

  const onDidRemovePanel = (id: string) => {
    application?.context.ideInteractor.deletePanel(id);
  };

  useEffect(() => {
    if (!organization || !workspace || !application) {
      return;
    }

    application.context.messageBus.sendInternal(LoadFileCommand, {
      fileId: `${organization.id}/${workspace.id}`,
      fileType: FileType.Space,
      version: undefined,
    });

    return () => {
      application.context.messageBus.sendInternal(DisconnectFileCommand, {
        fileId: `${organization.id}/${workspace.id}`,
        fileType: FileType.Space,
        version: undefined,
      });
    };
  }, [application, organization, workspace]);

  useEffect(() => {
    if (fileId) application?.context.ideInteractor.selectFile(fileId);
  }, [application?.context.ideInteractor, fileId]);

  useEffect(() => {}, []);

  const handleLogout = useCallback(() => {
    logout?.({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  const onSpaceClick = useCallback(
    (workspace: Workspace) => {
      setWorkspace(workspace);
      if (!organization || !workspace) return;
      setRecentSpacesByOrg(organization.id, workspace);
      searchParams.delete('entityId');
      setSearchParams(searchParams);
      navigate(
        getRoutePathFromParams(RoutePaths.Space, {
          organizationName: organization.name,
          workspaceName: workspace.name,
        }),
      );
    },
    [navigate, organization, searchParams, setRecentSpacesByOrg, setSearchParams, setWorkspace],
  );

  const spaces = organization?.workspaces
    .filter((ws) => ws.id !== workspace?.id)
    .slice(0, 8)
    .map((ws) => ({ ...ws, onClick: () => onSpaceClick(ws) }));

  const navigateToSpaces = useCallback(() => {
    if (!organization) return;
    navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
      state: {
        preventNavigation: true,
      },
    });
  }, [navigate, organization]);

  const onSaveSpaceRename = useCallback(
    async (name: string) => {
      if (!workspace || !name) return;
      const response = await renameWorkspace({
        variables: {
          args: {
            newName: name,
            workspaceId: workspace?.id,
          },
        },
      });
      if (!response.data) return;
      if (response.data.renameWorkspace.error) {
        addToast({ message: f({ id: 'space-rename-error' }), severity: 'error' });
      } else {
        addToast({
          message: f({ id: 'space-rename-success' }, { name: workspace?.name, newName: name }),
          severity: 'success',
        });
        trackEvent(AppTypeEvent.WorkspaceNameChanged, {
          workspaceId: workspace?.id,
          name: workspace?.name,
          newName: name,
        });
        await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
        const splitLocation = location.pathname.split('/');
        splitLocation[2] = encodeURIComponent(name);
        navigate(splitLocation.join('/'));
      }
      showWorkspaceSettingsVar(false);
    },
    [workspace, renameWorkspace, addToast, f, trackEvent, apolloClient, navigate],
  );

  const triggerCodegen = async () => {
    if (!organization || !workspace || !fileId) return;

    const payload = application?.getModelContext()?.serialize();
    const modelId = fileId.split('/')[1];

    await triggerCodeGeneration({
      variables: {
        args: {
          orgId: organization.id,
          spaceId: workspace?.id,
          modelId: [modelId],
          buildId: 'build-test',
          branch: 'main',
          payload: JSON.stringify(payload),
        },
      },
    });
  };

  const triggerImplement = async () => {
    if (!organization || !workspace || !fileId) return;

    const modelId = fileId.split('/')[1];

    await triggerImplementation({
      variables: {
        args: {
          orgId: organization.id,
          spaceId: workspace?.id,
          modelId: [modelId],
          buildId: 'build-test',
          branch: 'main',
        },
      },
    });
  };

  const triggerDeploy = async () => {
    if (!organization || !workspace || !fileId) return;

    const modelId = fileId.split('/')[1];

    await triggerDeployment({
      variables: {
        args: {
          orgId: organization.id,
          spaceId: workspace?.id,
          modelId: [modelId],
          buildId: 'build-test',
          branch: 'main',
        },
      },
    });
  };

  const cleanFiles = async () => {
    if (!organization || !workspace || !fileId) return;

    const modelId = fileId.split('/')[1];

    await triggerBuildClean({
      variables: {
        args: {
          orgId: organization.id,
          spaceId: workspace?.id,
          modelId: [modelId],
          buildId: 'build-test',
          branch: 'main',
        },
      },
    });
  };

  const devModeToggle = useCallback(
    (toggle: boolean) => {
      application?.context.ideInteractor.toggleDevMode(toggle);
    },
    [application],
  );

  return (
    <div className="bg-background-grey">
      <IdeLayout
        logs={buildLog}
        topMenu={{
          status: fileStatus ?? StatusEnum.Initial,
          spaceName: workspace?.name ?? '',
          logout: handleLogout,
          spaces: spaces || [],
          viewSpaces: navigateToSpaces,
          onSaveSpaceRename: onSaveSpaceRename,
          devModeToggle: devModeToggle,
          organizationName: organization?.name ?? '',
          menuItemSettingsActions: menuItemSettings,
          hasApps: isAutoUser(),
          triggerCodegen: triggerCodegen,
          triggerImplementation: triggerImplement,
          triggerDeployment: triggerDeploy,
          cleanFiles: cleanFiles,
          hideCodeActions: !getFlags().includes('CD'),
          isModelFile: isModalFile,
          hideLabels: true,
        }}
        sidebar={{
          onSelectFile: (item) => {
            if (!organization || !workspace) return;
            searchParams.delete('entityId');
            setSearchParams(searchParams);
            navigate(
              getRoutePathFromParams(RoutePaths.File, {
                organizationName: organization.name,
                workspaceName: workspace.name,
                fileId: encodeURI(String(item.id)),
              }),
            );
            application?.context.ideInteractor.selectFile(item.id);
          },
          structure: [], //explorer.results,
          user: { ...user, avatar: user.picture },
          filesTreeState: { ...fileTree, loading: !synced },
          logout: handleLogout,
          addNewFile: () => {},
        }}
        dockview={{ files: [], onDidRemovePanel: () => {} }}
      >
        {outlet ? (
          outlet
        ) : (
          <div className="w-full h-full bg-muted flex items-center justify-center">
            <AutoLogoSkeleton />
          </div>
        )}
      </IdeLayout>
    </div>
  );
};
