import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Name } from '../name/name';
import { useUpdateEntity } from '../../hooks/use-update-entity';
import { Box } from '@mui/material';
import { Overlay } from '../overlay/overlay';
import { ConstructNodeData, EntityType, Label } from '@xspecs/single-source-model';
import { ScriptToggleButton } from './script-toggle-button';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  arrow,
  autoPlacement,
  autoUpdate,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { LabelsStack } from '../../../../labels/labels-stack/labels-stack';
import { useLabels } from '../../floating/menu/labels-details-button/use-labels';
import {
  FloatingLabelsSelector,
  FloatingLabelsSelectorProps,
} from '../../../../labels/floating-labels-selector/floating-labels-selector';
import { createPortal } from 'react-dom';
import { FloatingMenu } from '../../floating-menu/floating-menu';
import { FloatingDetailsView } from '../../floating-details-view/floating-details-view';
import { useActiveOrganization } from '../../../../../hooks/use-active-organization';
import { ReactFlowRendererPortal } from '../../reactflow-renderer-portal/reactflow-renderer-portal';
import { cn } from '@xspecs/design-system';

export const ConstructNode = (props: NodeProps<Node<ConstructNodeData>>) => {
  const { id, selected, data, dragging } = props;

  const { onRename, onFontSizeChange } = useUpdateEntity(id);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [floatingViewClicked, setFloatingViewClicked] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open);
      setFloatingViewClicked(false);
    },
    placement: 'right',
    middleware: [
      offset(20),
      autoPlacement({
        allowedPlacements: ['right'],
      }),
      arrow({ element: arrowRef.current }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const offsetFromAttachments = useMemo(
    () =>
      data.attachments
        ?.filter((attachment) => attachment.subType === 'Actor')
        .reduce((offset, attachment) => offset + attachment.height + attachment.attachmentsPadding, 0) ?? 0,
    [data.attachments],
  );

  const backgroundContainerStyle = useMemo(
    () => ({
      backgroundColor: data.backgroundColor,
      border: data.border,
    }),
    [data.backgroundColor, data.border],
  );

  // const onClick = useCallback(async () => {
  //   setTimeout(() => {
  //     if (!data.scriptId) return;
  //     const script = application?.getModelContext()?.entityRepository.get(data.scriptId);
  //     if (!script || script.type === EntityType.ActionScript) return;
  //     if (script.isVisible) fitView({ nodes: [{ id: id }, { id: data.scriptId }], duration: 500 });
  //   }, 300);
  // }, [data.scriptId, application, fitView, id]);

  const onShowDetails = useCallback(() => {
    setIsOpen(true);
    setShowFloatingMenu(false);
  }, []);

  const { organization } = useActiveOrganization();

  const detailsViewEntity = useMemo(
    () => ({
      id: organization!.id + '/' + id,
      type: data.type,
      name: data.name,
      editor: data.editor,
    }),
    [data.editor, data.name, data.type, id, organization],
  );

  useEffect(() => {
    if (!selected) {
      setShowFloatingMenu(false);
    }
  }, [selected]);

  const onNameClick = useCallback(() => {
    setShowFloatingMenu((prev) => !prev);
  }, []);

  return (
    <Fragment>
      <div className="size-full rounded-md" style={backgroundContainerStyle} />
      <Handles color={data.color} />
      {data.hasOverlay ? <Overlay /> : null}
      <Name
        ref={refs.setReference}
        id={id}
        value={data.name}
        onRename={onRename}
        onFontSizeChange={onFontSizeChange}
        isSelected={Boolean(selected)}
        isNew={data.isNew}
        isDragging={dragging}
        className="node"
        fontSize={data.attributes.fontSize}
        onClick={onNameClick}
        {...getReferenceProps()}
      />
      {data.hasScript ? (
        <ScriptToggleButton
          id={id}
          hasOverlay={data.hasOverlay}
          isExpanded={Boolean(data.isExpanded)}
          type={data.type as EntityType}
          // onClick={onClick}
        />
      ) : null}
      <FloatingMenu
        isVisible={showFloatingMenu}
        id={id}
        type={data.type}
        labels={data.labels}
        name={data.name}
        withCopy
        onShowDetails={onShowDetails}
      />
      {data.labels.length > 0 ? (
        <CanvasLabelsStack
          id={id}
          labels={data.labels}
          hasOverlay={data.hasOverlay}
          topOffset={offsetFromAttachments}
        />
      ) : null}
      {isOpen ? (
        <ReactFlowRendererPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={cn('z-10 w-[555px] h-[470px]', floatingViewClicked && 'nodrag nowheel')}
            onClick={() => setFloatingViewClicked(true)}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} />
            <FloatingDetailsView entity={detailsViewEntity} />
          </div>
        </ReactFlowRendererPortal>
      ) : null}
    </Fragment>
  );
};

// Canvas Labels Stack
type CanvasLabelsStackProps = {
  id: string;
  hasOverlay: boolean;
  labels: Label[];
  topOffset: number;
};

const CanvasLabelsStack = memo((props: CanvasLabelsStackProps) => {
  const { hasOverlay, id, topOffset, labels: selectedLabels } = props;
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating(
    useMemo(
      () => ({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'right',
        middleware: [
          offset(15),
          autoPlacement({
            allowedPlacements: ['right', 'right-start', 'right-end'],
          }),
          arrow({ element: arrowRef.current }),
        ],
        whileElementsMounted: autoUpdate,
      }),
      [isOpen],
    ),
  );
  const click = useClick(context);
  const dismiss = useDismiss(
    context,
    useMemo(() => ({ referencePress: false }), []),
  );
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);
  const { labels, createAndSelectLabel, onSelectedLabelsChange, onManageLabels } = useLabels(id);

  const sx = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      width: 'calc(100% - 8px)',
      transform: `translate(0, calc(-100% - 4px - ${topOffset}px))`,
    }),
    [topOffset],
  );

  const floatingLabelsSelectorProps = useMemo<FloatingLabelsSelectorProps>(
    () => ({
      RootProps: { className: 'nowheel', ref: refs.setFloating, sx: floatingStyles, ...getFloatingProps() },
      context,
      arrowRef,
      selectorProps: { labels, selectedLabels, onSelectedLabelsChange, createAndSelectLabel, onManageLabels },
    }),
    [
      context,
      createAndSelectLabel,
      floatingStyles,
      getFloatingProps,
      labels,
      onManageLabels,
      onSelectedLabelsChange,
      refs.setFloating,
      selectedLabels,
    ],
  );

  return (
    <Fragment>
      <Box ref={refs.setReference} sx={sx} {...getReferenceProps()}>
        <LabelsStack hasOverlay={hasOverlay} labels={selectedLabels} />
      </Box>
      {isOpen ? createPortal(<FloatingLabelsSelector {...floatingLabelsSelectorProps} />, document.body) : null}
    </Fragment>
  );
});
CanvasLabelsStack.displayName = 'CanvasLabelsStack';

// Handles
const Handles = ({ color }: { color: string }) => {
  const styles = useMemo(
    () => ({
      backgroundColor: color,
    }),
    [color],
  );

  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} style={styles} />
      <Handle id={Position.Right} type="source" position={Position.Right} style={styles} />
      <Handle id={Position.Top} type="source" position={Position.Top} style={styles} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} style={styles} />
    </Fragment>
  );
};
