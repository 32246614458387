import { Plate, PlateContent } from '@udecode/plate-common';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Box, SxProps, Theme } from '@mui/material';
import { getDocEditorPlugins } from './doc-editor.plugins';
import { getToken } from '../../../utils/getToken';
import { MentionUserCombobox } from '../../mention-user-combobox/mention-user-combobox';
import { renderCustomEditable } from '../../renderers/render-custom-editable/render-custom-editable';
import { InsertMediaUpload } from '../../insert-media-modal/insert-media-modal';
import { DocEditorFooter } from './footer/doc-editor-footer';
import { FloatingBalloonMenu } from '../floating-balloon-menu/floating-balloon-menu';
import { FloatingInsertMenu } from '../floating-insert-menu/floating-insert-menu';
import { useIntl } from 'react-intl';
import { MEDIA_AUTH_QUERY } from '../../../graphql/queries';
import { Loading } from '../../loading/loading';
import { FileStatus } from '../../file-status/file-status';
import { logger } from '@xspecs/logger';

type DocEditorProps = {
  documentId: string;
  rootSx?: SxProps<Theme>;
  placeholder?: string;
};

const _DocEditor = (props: DocEditorProps & { mediaAuthToken: string }) => {
  const { documentId, rootSx = {}, mediaAuthToken, placeholder } = props;

  const { formatMessage: f } = useIntl();

  const plugins = useMemo(() => {
    if (!mediaAuthToken) return [];
    const token = getToken();
    if (!token) {
      logger.error('No token found');
      return [];
    }
    return getDocEditorPlugins(mediaAuthToken, token);
  }, [mediaAuthToken]);

  return (
    <Box data-testid="doc-editor-root" sx={rootSx} className="p-2">
      <Plate data-testid="plate-provider" id={documentId} plugins={plugins}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box sx={{ overflow: 'visible', flexGrow: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flexGrow: 1,
                height: '100%',
              }}
            >
              <PlateContent
                id={documentId}
                renderEditable={renderCustomEditable}
                style={{ outline: 'none' }}
                placeholder={placeholder ?? f({ id: 'doc-editor.placeholder' })}
              />
              <DocEditorFooter />
            </Box>
          </Box>
        </Box>
        <MentionUserCombobox />
        <FloatingBalloonMenu />
        <FloatingInsertMenu />
        <InsertMediaUpload />
      </Plate>
      <FileStatus fileId={documentId} />
    </Box>
  );
};

export const DocEditor = (props: DocEditorProps) => {
  const { data, loading } = useQuery(MEDIA_AUTH_QUERY);

  if (loading || !data?.mediaAuth.token) {
    return <Loading />;
  }

  return <_DocEditor {...props} mediaAuthToken={data.mediaAuth.token} />;
};
