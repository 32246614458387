import { MessageBus } from './commands/framework/MessageBus';
import { register } from './commands/Register';
import { localStoreProvider } from './data/LocalStoreProvider';
import { IStore } from './data/Store';
import { ErrorStore } from './ErrorStore';
import { WorkerManager } from './apps/WorkerManager';
import { Palette } from './palette/Palette';
import { SidebarInteractor } from './interactors/SidebarInteractor';
import { Toolbar } from './read-models/toolbar/Toolbar';
import { FileTree } from './read-models/file-tree/FileTree';
import { Ide } from './read-models/ide/Ide';
import { IdeInteractor } from './interactors/Ide';
import { SpaceRepository } from './data/SpaceRepository';
import { FileStoreClient } from './data/FileStoreClient';
import { SpaceSettingsRepository } from './data/SpaceSettingsRepository';
import { NotificationTypes, SingleSourceObserver } from './observable/SingleSourceObserver';
import { User } from './types';
import { isBrowser } from './utils';

export class ApplicationContext {
  private static instance: ApplicationContext | null;
  public readonly fileTree: FileTree;
  public readonly sidebarInteractor: SidebarInteractor;
  public readonly ideInteractor: IdeInteractor;
  public isLoaded = false;
  public readonly messageBus: MessageBus;
  public readonly workerManager: WorkerManager;
  public readonly palette: Palette;
  public readonly toolbar: Toolbar;
  public readonly ide: Ide;
  public readonly spaceRepository: SpaceRepository;
  public readonly spaceSettingsRepository: SpaceSettingsRepository;
  public readonly fileStoreClient: FileStoreClient;
  public observer: SingleSourceObserver;
  public errorStore: ErrorStore;

  private constructor(
    public readonly currentUser: User,
    public readonly store: IStore,
    fileStoreClient: FileStoreClient,
  ) {
    this.messageBus = new MessageBus(this);
    this.observer = new SingleSourceObserver();
    register(this.messageBus, this);
    this.fileTree = new FileTree(store);
    this.sidebarInteractor = new SidebarInteractor(this);
    this.ideInteractor = new IdeInteractor(this);
    this.workerManager = new WorkerManager(this.messageBus, this.store);
    this.palette = new Palette(this.store);
    this.toolbar = new Toolbar(this.store);
    this.ide = new Ide(store);
    this.spaceRepository = new SpaceRepository(this.messageBus);
    this.spaceSettingsRepository = new SpaceSettingsRepository(this.messageBus);
    this.fileStoreClient = fileStoreClient;
    this.errorStore = new ErrorStore(this.observer);
    localStoreProvider.setPersistedStore(store);
    this.projectReadModels();
  }

  static initialize(user: User, store: IStore, fileStoreClient: FileStoreClient): ApplicationContext {
    if (!this.instance) {
      this.instance = new ApplicationContext(user, store, fileStoreClient);
    }
    if (isBrowser) {
      window['app'] = this.instance;
    }
    return this.instance;
  }

  public undo() {
    this.observer.notify(NotificationTypes.OnUndo);
  }

  public redo() {
    this.observer.notify(NotificationTypes.OnRedo);
  }

  static getInstance(): ApplicationContext {
    if (!this.instance) {
      throw new Error('ApplicationState has not been initialized. Call ApplicationState.initialize() first.');
    }
    return this.instance;
  }

  public dispose() {
    ApplicationContext.instance = null;
    this.fileTree.dispose();
  }

  private projectReadModels() {
    this.observer.subscribe([NotificationTypes.OnError], () => {
      this.store.getState().setErrors([...this.errorStore.errors]);
    });
  }
}
