import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SearchModal } from '../../components/search-modal/search-modal';
import { WorkspaceSettingsModal } from '../../components/workspace-settings-modal/workspace-settings-modal';
import { OrganizationSettingsModal } from '../../components/organization-settings-modal/organization-settings-modal';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { InviteUsersModal } from '../../components/invite-users-modal/invite-users-modal';
import { Loading } from '../../components/loading/loading';
import { KeyboardShortcutsModal } from '../../components/keyboard-shortcuts-modal/keyboard-shortcuts-modal';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { getToken } from '../../utils/getToken';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useApplication } from '../../wrappers/application-context/application-context';
import { useAuth } from '../../auth';
import { logger } from '@xspecs/logger';
import { Analytics, AppTypeEvent } from '@xspecs/single-source-model';
import { getFlags, LAST_SELECTED_ORGANIZATION_ID_KEY, LAST_SELECTED_WORKSPACE_BY_ORG } from '../../lib/utils';
import { MouseInputSelectionDialog, MouseInputValue, toast } from '@xspecs/design-system';
import { useIntl } from 'react-intl';

export const OrganizationRoute = () => {
  const { organization, setOrganization } = useActiveOrganization();
  const { workspace, setWorkspace } = useActiveWorkspace();
  const { formatMessage: f } = useIntl();

  const { organizationName, workspaceName, fileId } = useParams<{
    organizationName: string;
    workspaceName?: string;
    fileId?: string;
  }>();

  const [isReady, setIsReady] = useState(false);

  const lastTrackedWorkspaceRef = useRef<string | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();
  const { application } = useApplication();
  const { user } = useAuth();

  const [fetchOrganizations, { loading }] = useLazyQuery(ORGANIZATIONS_QUERY);

  const showMouseTrackpadSettings = useSingleSourceStore.use.showMouseTrackpadSettings();
  const setShowMouseTrackpadSettings = useSingleSourceStore.use.setShowMouseTrackpadSettings();
  const mouseInputMode = useSingleSourceStore.use.mouseInputMode();
  const setMouseInputMode = useSingleSourceStore.use.setMouseInputMode();

  const onMouseTrackpadSettingsChange = useCallback(
    (open: boolean) => {
      setShowMouseTrackpadSettings(open);
    },
    [setShowMouseTrackpadSettings],
  );

  const onSave = useCallback(
    (selected: MouseInputValue) => {
      setMouseInputMode(selected);
      setShowMouseTrackpadSettings(false);
      toast.success(f({ id: 'switched-to-mode' }, { mode: selected }));
    },
    [f, setMouseInputMode, setShowMouseTrackpadSettings],
  );

  const resolveOrganizationAndWorkspace = useCallback(async () => {
    if (organizationName === organization?.name && workspaceName === workspace?.name) return;
    if (!organizationName) {
      return;
    }
    const response = await fetchOrganizations();
    if (!response.data) {
      logger.error('Failed to fetch organizations');
      return;
    }
    const { organizations } = response.data;
    const foundOrganization = organizations.find((organization) => organization.name === organizationName);
    if (!foundOrganization) {
      localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
      navigate(RoutePaths.ChooseOrganization);
      return;
    }

    localStorage.setItem('workspaces', JSON.stringify(foundOrganization.workspaces.map((w) => w.id)));
    setOrganization(foundOrganization);

    setIsReady(true);

    if (foundOrganization.workspaces.length === 0) {
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName }), {
        state: {
          preventNavigation: true,
        },
      });
      return;
    }

    const lastSelectedWorkspaceByOrg = JSON.parse(
      // localStorage.getItem(LAST_SELECTED_WORKSPACE_BY_ORG) ??
      '{}',
    );
    const lastSelectedWorkspaceId = lastSelectedWorkspaceByOrg[foundOrganization.id];
    const lastSelectedWorkspace = foundOrganization.workspaces.find(
      (workspace) => workspace.id === lastSelectedWorkspaceId,
    );
    if (!lastSelectedWorkspace) {
      delete lastSelectedWorkspaceByOrg[foundOrganization.id];
      localStorage.setItem(LAST_SELECTED_WORKSPACE_BY_ORG, JSON.stringify(lastSelectedWorkspaceByOrg));
    }
    const defaultWorkspace = lastSelectedWorkspaceId ? lastSelectedWorkspace : foundOrganization.workspaces[0];

    const foundWorkspace = workspaceName
      ? foundOrganization.workspaces.find((workspace) => workspace.name === workspaceName)
      : defaultWorkspace;

    if (!foundWorkspace) {
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName }), {
        state: {
          preventNavigation: true,
        },
      });
      return;
    }

    setWorkspace(foundWorkspace);

    if (!location?.state?.preventNavigation && foundWorkspace) {
      if (fileId) {
        navigate(
          getRoutePathFromParams(RoutePaths.File, {
            organizationName,
            workspaceName: foundWorkspace.name,
            fileId: fileId,
          }),
        );
      } else {
        navigate(
          getRoutePathFromParams(RoutePaths.Space, {
            organizationName,
            workspaceName: foundWorkspace.name,
          }),
        );
      }
    }
  }, [
    organizationName,
    organization?.name,
    workspaceName,
    workspace?.name,
    fetchOrganizations,
    setOrganization,
    setWorkspace,
    location?.state?.preventNavigation,
    navigate,
    fileId,
  ]);

  useEffect(() => {
    resolveOrganizationAndWorkspace().catch(() => {});
  }, [resolveOrganizationAndWorkspace]);

  useEffect(() => {
    if (organization) {
      localStorage.setItem(LAST_SELECTED_ORGANIZATION_ID_KEY, organization.id);
    }
  }, [organization]);

  useEffect(() => {
    if (organization) {
      Analytics.updateOrganization(organization.name);
    }

    return () => {
      Analytics.updateOrganization(undefined);
    };
  }, [organization]);

  useEffect(() => {
    if (workspace) {
      Analytics.updateWorkspace(workspace.name);
    }

    return () => {
      Analytics.updateWorkspace(undefined);
    };
  }, [workspace]);

  useEffect(() => {
    if (organization) {
      Analytics.getInstance().track({
        event: AppTypeEvent.OrganizationSelected,
        params: {
          organization: organization.name,
        },
      });
    }
  }, [organization]);

  useEffect(() => {
    if (organization && workspace && workspace.id !== lastTrackedWorkspaceRef.current) {
      Analytics.getInstance().track({
        event: AppTypeEvent.WorkspaceSelected,
        params: {
          organization: organization.name,
          workspace: workspace.name,
        },
      });
      lastTrackedWorkspaceRef.current = workspace.id;
    }
  }, [organization, workspace]);

  useEffect(() => {
    const token = getToken();
    if (!token || !user) return;
    application?.start({ token, user, FF: getFlags() });
  }, [application, user]);

  return (
    <>
      <Box data-testid="home-route" sx={{ height: '100%', width: '100%' }}>
        {loading || !isReady ? (
          <Loading />
        ) : (
          <Box height="100%" display="flex" flexDirection="column">
            <Outlet />
            <OrganizationSettingsModal />
            <WorkspaceSettingsModal />
            <InviteUsersModal />
            <KeyboardShortcutsModal />
          </Box>
        )}
        <SearchModal />
        <MouseInputSelectionDialog
          onChange={onMouseTrackpadSettingsChange}
          onSave={onSave}
          open={showMouseTrackpadSettings}
          value={mouseInputMode}
        />
      </Box>
    </>
  );
};
