import { Box, SxProps, TextField, Theme } from '@mui/material';
import { Loading } from '../loading/loading';
import { FileList, FileListProps } from '../search-modal/file-list/file-list';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_QUERY } from '../../graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { SearchFile } from '../../state/types';
import { QuerySearchArgs } from '../../gql/graphql';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';

export type SearchFilesProps = {
  autoFocus?: boolean;
  rootSxOverrides?: SxProps<Theme>;
} & Pick<FileListProps, 'listSxOverrides' | 'isSelectable' | 'onSelectedChange' | 'selectedFiles'> &
  Pick<QuerySearchArgs, 'excludeFileTypes'>;

export const SearchFiles = (props: SearchFilesProps) => {
  const { autoFocus, rootSxOverrides = {}, onSelectedChange, excludeFileTypes, listSxOverrides } = props;

  const [files, setFiles] = useState<SearchFile[]>([]);

  const { formatMessage: f } = useIntl();

  const { workspace } = useActiveWorkspace();

  const [search, { data, loading }] = useLazyQuery(SEARCH_QUERY, { fetchPolicy: 'no-cache' });

  const debouncedSearch = debounce(search, 500);

  const onChange = useCallback(
    async (e) => {
      if (!workspace) return;
      await debouncedSearch({
        variables: {
          input: e.target.value,
          workspaceId: workspace.id,
          excludeFileTypes,
        },
      });
    },
    [workspace, debouncedSearch, excludeFileTypes],
  );

  useEffect(() => {
    if (workspace) {
      // eslint-disable-next-line no-console
      search({ variables: { input: '', workspaceId: workspace.id, excludeFileTypes } }).catch(console.error);
    }
  }, [excludeFileTypes, workspace, search]);

  useEffect(() => {
    if (data?.search) setFiles(data.search);
    return () => setFiles([]);
  }, [data?.search]);

  return (
    <Box sx={rootSxOverrides}>
      <TextField
        autoFocus={autoFocus}
        fullWidth
        placeholder={f({ id: 'search' })}
        onChange={onChange}
        inputProps={{ 'data-testid': 'search-input' }}
      />
      {loading ? <Loading /> : <FileList files={files} onSelectedChange={onSelectedChange} {...props} />}
    </Box>
  );
};
