import { Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { PlansList } from './list/plans-list';
import { Fragment, useMemo } from 'react';
import { Loading } from '../../loading/loading';
import { usePlans } from '../../../hooks/use-plans';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const Plans = () => {
  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();

  const { plans, currentPlan, loading } = usePlans();

  const description = useMemo(() => {
    return f(
      { id: 'organization-plan-description' },
      {
        plan: currentPlan?.name,
        name: organization?.name,
        link: (
          <Link key="current-plan-link" sx={{ cursor: 'pointer' }} href={'mailto:support@on.auto'}>
            {f({ id: 'here' })}
          </Link>
        ),
      },
    );
  }, [currentPlan?.name, f, organization?.name]);

  return (
    <div id="plans" className="w-full bg-background p-6 rounded-lg shadow-sm">
      <h4 className="text-2xl text-card-foreground font-semibold mb-1.5">{f({ id: 'plan' })}</h4>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <p className="text-sm text-muted-foreground mb-6">{description}</p>
          <PlansList plans={plans} />
        </Fragment>
      )}
    </div>
  );
};
