import { FormattedNumber, useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import { CustomerPortalButton } from './billing-history/customer-portal-button';
import { usePlans } from '../../../hooks/use-plans';
import { Loading } from '../../loading/loading';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const Billing = () => {
  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();

  const { currentPlan, loading } = usePlans();

  if (loading) {
    return <Loading />;
  }

  return (
    <div id="billing" className="w-full bg-background p-6 rounded-lg shadow-sm flex flex-col gap-6">
      <div>
        <h4 className="text-2xl text-card-foreground font-semibold mb-1.5">{f({ id: 'billing' })}</h4>
        <p className="text-sm text-muted-foreground">{f({ id: 'manage-org-billing' })}</p>
      </div>
      {organization?.numberOfSeats ? (
        <Stack>
          <Typography variant="caption" color="text.primary"></Typography>
          <FormattedNumber
            value={currentPlan?.price ?? 0}
            style="currency"
            currency={currentPlan?.currency}
            maximumFractionDigits={0}
          >
            {(value) => (
              <Stack width="100%" direction="row" alignItems="center" gap={0.5}>
                <Typography variant="caption">
                  {f({ id: 'n-users' }, { n: organization?.numberOfSeats ?? 0 }) + ' @'}
                </Typography>
                <Stack width="100%" direction="row" gap={0.5} alignItems="center" whiteSpace="nowrap">
                  <Typography variant="caption">{value}</Typography>
                  <Typography variant="caption">
                    {f({ id: 'per-user-per-period' }, { period: currentPlan?.priceInterval })}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </FormattedNumber>
        </Stack>
      ) : null}
      <div>
        <h3 className="text-primary font-medium mb-1.5">{f({ id: 'invoices' })}</h3>
        <CustomerPortalButton />
      </div>
    </div>
  );
};
