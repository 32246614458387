import { useQuery } from '@apollo/client';
import { USERS_QUERY } from '../../../../../graphql/queries';
import { useMemo } from 'react';
import { Avatar, AvatarGroup, SxProps, Theme } from '@mui/material';
import { UsersQuery } from '../../../../../gql/graphql';
import { Loading } from '../../../../loading/loading';
import { useActiveOrganization } from '../../../../../hooks/use-active-organization';
import { Check } from 'lucide-react';

type ThreadNodePrimitiveProps = {
  getReferenceProps: () => any;
  participantIds: string[];
  isResolved: boolean;
};

export const ThreadNodePrimitive = (props: ThreadNodePrimitiveProps) => {
  const { participantIds, isResolved } = props;

  const { organization } = useActiveOrganization();

  const { data, loading } = useQuery(USERS_QUERY, {
    variables: {
      organizationId: organization ? organization.id : '',
    },
    skip: !organization?.id,
  });

  const userMap = useMemo(() => {
    return (
      data?.users.reduce<Record<string, UsersQuery['users'][0]>>((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {}) ?? {}
    );
  }, [data?.users]);

  return (
    <div className="max-w-[71px] min-w-[28px] h-7 bg-white rounded-md rounded-tl-none cursor-pointer shadow-sm">
      {isResolved ? (
        <div className="w-full h-full flex items-center justify-center">
          <Check className="text-icon-dark" />
        </div>
      ) : loading ? (
        <Loading />
      ) : (
        <AvatarGroup max={3}>
          {participantIds.map((participantId) => (
            <Avatar
              key={participantId}
              alt={userMap[participantId]?.name ?? 'Unknown User'}
              src={userMap[participantId]?.picture ?? undefined}
              sx={avatarSx}
            />
          ))}
        </AvatarGroup>
      )}
    </div>
  );
};

const avatarSx: SxProps<Theme> = {
  height: 24,
  width: 24,
};
