import { EntityType } from './entities/EntityType';

interface Position {
  x: number;
  y: number;
}

interface Rect extends Position {
  width: number;
  height: number;
}

export type CellularCacheItem = {
  id: string;
  type: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

export const getOverlappingArea = (rectA: Rect, rectB: Rect): number => {
  const xOverlap = Math.max(0, Math.min(rectA.x + rectA.width, rectB.x + rectB.width) - Math.max(rectA.x, rectB.x));
  const yOverlap = Math.max(0, Math.min(rectA.y + rectA.height, rectB.y + rectB.height) - Math.max(rectA.y, rectB.y));

  return Math.ceil(xOverlap * yOverlap);
};

export const nodeToRect = (node: CellularCacheItem): Rect => ({
  x: node.x || 0,
  y: node.y || 0,
  width: node.width || 0,
  height: node.height || 0,
});

const stringifyCircularJSON = (obj: any) => {
  const seen = new WeakSet();
  return JSON.stringify(obj, (k, v) => {
    if (v !== null && typeof v === 'object') {
      if (seen.has(v)) return;
      seen.add(v);
    }
    return v;
  });
};

export const circularStringify = (obj: any) => JSON.stringify(JSON.parse(stringifyCircularJSON(obj)), null, 2);

export const entitiesWithScript: (EntityType | string)[] = [EntityType.Narrative, EntityType.Action] as const;

export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const preloadImage = (imageUrl: string) => {
  if (!isBrowser) {
    return;
  }
  const img = new Image();
  img.src = imageUrl;
  img.onload = () => {
    // Image preloaded successfully
  };
  return img;
};
