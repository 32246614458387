import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../../../graphql/queries';
import { arraysAreEqualUnsorted, isValidDomainName } from '../../../../utils';
import { UPDATE_ORGANIZATION_EMAIL_DOMAINS } from '../../../../graphql/mutations';
import { Loading } from '../../../loading/loading';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';

type OrganizationSettingsModalGeneralJoiningOrganizationProps = {
  onClose: () => void;
};

enum JoinType {
  InviteOnly = 'InviteOnly',
  InviteAndDomain = 'InviteAndDomain',
}

export const OrganizationSettingsModalGeneralJoiningOrganization = (
  props: OrganizationSettingsModalGeneralJoiningOrganizationProps,
) => {
  const { onClose } = props;

  const [type, setType] = useState<JoinType | null>(JoinType.InviteOnly);
  const [allowedEmailDomains, setAllowedEmailDomains] = useState<string>('');
  const [error, setError] = useState('');

  const { organization } = useActiveOrganization();

  const { data, loading, refetch } = useQuery(ORGANIZATIONS_QUERY, { fetchPolicy: 'no-cache' });
  const [updateWhiteListedDomains, { loading: updatingOrganizationEmailDomains }] = useMutation(
    UPDATE_ORGANIZATION_EMAIL_DOMAINS,
  );

  const { formatMessage: f } = useIntl();

  const organizationAllowedEmailDomains = useMemo(() => {
    const org = data?.organizations.find((w) => w.name === organization?.name);
    return org?.allowedEmailDomains.map((aed) => aed.domain) || [];
  }, [data?.organizations, organization?.name]);

  const areAllowedEmailDomainsInvalid = useMemo(() => {
    const domains = allowedEmailDomains.split(',').map((d) => d.trim());
    if (domains.length == 0) return false;
    return !domains.every((d) => Boolean(d) && isValidDomainName(d));
  }, [allowedEmailDomains]);

  const onTypeChange = useCallback((event) => {
    setError('');
    setType(event.target.value);
  }, []);

  const onAllowedEmailDomainsChange = useCallback((event) => {
    setError('');
    setAllowedEmailDomains(event.target.value);
  }, []);

  const onSave = useCallback(async () => {
    if (!organization) return;
    const domains = type === JoinType.InviteOnly ? [] : sanitizeAllowedEmailDomains(allowedEmailDomains);
    const response = await updateWhiteListedDomains({
      variables: {
        args: {
          organizationId: organization.id,
          domains,
        },
      },
    });
    if (!response.data) return;
    if (response.data.updateOrganizationEmailDomains.error) {
      setError(response.data.updateOrganizationEmailDomains.error);
    } else {
      await refetch();
    }
  }, [organization, allowedEmailDomains, refetch, type, updateWhiteListedDomains]);

  const isSaveDisabled = useMemo(() => {
    switch (type) {
      case JoinType.InviteAndDomain:
        return (
          areAllowedEmailDomainsInvalid ||
          arraysAreEqualUnsorted(sanitizeAllowedEmailDomains(allowedEmailDomains), organizationAllowedEmailDomains)
        );
      case JoinType.InviteOnly:
        return organizationAllowedEmailDomains.length === 0;
    }
  }, [allowedEmailDomains, areAllowedEmailDomainsInvalid, type, organizationAllowedEmailDomains]);

  const isTextFieldDisabled = type === JoinType.InviteOnly;

  useEffect(() => {
    setAllowedEmailDomains(organizationAllowedEmailDomains.join(', '));
    setType(organizationAllowedEmailDomains.length > 0 ? JoinType.InviteAndDomain : JoinType.InviteOnly);
  }, [organizationAllowedEmailDomains]);

  if (loading || updatingOrganizationEmailDomains) {
    return <Loading />;
  }

  return (
    <Stack px={1} width="100%">
      <Typography variant="h6">{f({ id: 'joining-this-organization' })}</Typography>
      <RadioGroup defaultValue={JoinType.InviteOnly} value={type} onChange={onTypeChange}>
        <FormControlLabel value={JoinType.InviteOnly} control={<Radio />} label={f({ id: 'by-invite-only' })} />
        <FormControlLabel
          value={JoinType.InviteAndDomain}
          control={<Radio />}
          label={f({ id: 'invite-and-domains' })}
        />
      </RadioGroup>
      <TextField
        inputProps={{ 'data-testid': 'OrganizationSettingsModalGeneralJoiningOrganizationTextField' }}
        helperText={error ? f({ id: error }) : f({ id: 'separate-by-comma' })}
        disabled={isTextFieldDisabled}
        value={allowedEmailDomains}
        onChange={onAllowedEmailDomainsChange}
        error={Boolean(error) || areAllowedEmailDomainsInvalid}
        sx={{ py: 1 }}
        multiline
      />
      <Stack spacing={2}>
        <Button
          data-testid="OrganizationSettingsModalGeneralSaveButton"
          fullWidth
          variant="contained"
          onClick={onSave}
          disabled={isSaveDisabled}
        >
          {f({ id: 'save' })}
        </Button>
        <Button
          data-testid="OrganizationSettingsModalGeneralCancelButton"
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          {f({ id: 'cancel' })}
        </Button>
      </Stack>
    </Stack>
  );
};

function sanitizeAllowedEmailDomains(domains: string) {
  return domains
    .split(',')
    .map((d) => d.trim())
    .filter(isValidDomainName);
}
